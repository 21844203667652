import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {Container, Row, Col, Breadcrumb, Form, FloatingLabel, Button} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import PageTitle from 'components/pageTitle/PageTitle';
import settingsServices from 'store/settingsServices';
import useSettings from 'hooks/useSettings';
import {LinkOpenType} from 'models/enums/LinkOpenType.enum';

const Gamble: FunctionComponent = function Gamble() {
	const translations = useL10n();
	const [validatedUrl, setValidatedUrl] = useState(false);
	const [validatedInterval, setValidatedInterval] = useState(false);

	const {settings} = useLocalObservable(() => settingsServices);
	const {patchSettings} = useSettings();

	const [mySettings, setMySettings] = useState<{
		enableGamble?: boolean;
		enableGambleConditions?: boolean;
		gambleIntervalMS?: number;
		gambleUrl?: string;
		gambleBearer?: string;
		gambleOpenType?: LinkOpenType;
	}>({});

	const onChangeUrlHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setMySettings({
			...mySettings,
			gambleUrl: value,
		});
	};

	const onChangeBearerHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setMySettings({
			...mySettings,
			gambleBearer: value,
		});
	};

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked, name, value} = event.target;
		setMySettings({
			...mySettings,
			[name]: checked,
		});

		patchSettings({
			[name]: checked,
		});
	};

	const onChangeOpenTypeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setMySettings({
			...mySettings,
			gambleOpenType: value as LinkOpenType,
		});

		patchSettings({
			gambleOpenType: value as LinkOpenType,
		});
	};

	const onChangeIntervalHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		let value: any = event.target.value.replace(/[^\d.]/g, '');

		value = value.replace(/^([^.]*\.)(.*)$/, (a: any, b: any, c: any) => {
			return b + c.replace(/\./g, '');
		});

		value = +value;

		if (value > 1440) value = 1440;

		setMySettings({
			...mySettings,
			[event.target.name]: value,
		});
	};

	const handleSubmitUrl = async (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		const e: any = event;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) setValidatedUrl(true);
		else {
			patchSettings({
				gambleUrl: mySettings.gambleUrl,
				gambleBearer: mySettings.gambleBearer,
			});
		}
	};

	const handleSubmitInterval = async (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		const e: any = event;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) setValidatedInterval(true);
		else {
			patchSettings({
				gambleIntervalMS: mySettings.gambleIntervalMS
					? mySettings.gambleIntervalMS * 60000
					: mySettings.gambleIntervalMS,
			});
		}
	};

	const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
		const value = +event.target.value;
		setMySettings({
			...mySettings,
			[event.target.name]: value,
		});
	};

	useEffect(() => {
		settings &&
			setMySettings({
				enableGamble: settings?.enableGamble,
				gambleUrl: settings?.gambleUrl,
				gambleBearer: settings?.gambleBearer,
				enableGambleConditions: settings?.enableGambleConditions,
				gambleIntervalMS: settings?.gambleIntervalMS
					? settings.gambleIntervalMS / 60000
					: settings.gambleIntervalMS,
			});
	}, [settings]);

	return (
		<Container fluid className='pt-4'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.gambles.title}</Breadcrumb.Item>
					</Breadcrumb>
					<PageTitle
						title={translations.sidebar.gambles.title}
						text={translations.sidebar.gambles.descr}
					/>
				</Col>
			</Row>
			<div className='block w-50 mb-5'>
				<div>
					<Form.Check
						onChange={onChangeHandler}
						type='switch'
						name='enableGamble'
						id='enableGamble'
						label={translations.gambles.enableWinPublication.title}
						checked={mySettings.enableGamble}
						className='mb-2'
					/>
					<p className='text-secondary settings__description'>
						{translations.gambles.enableWinPublication.description}
					</p>
				</div>
				<div className='mb-5'>
					<Form.Check
						onChange={onChangeHandler}
						type='switch'
						name='enableGambleConditions'
						id='enableGambleConditions'
						label={translations.gambles.enableGambleConditions.title}
						checked={mySettings.enableGambleConditions}
						className='mb-2'
					/>
					<p className='text-secondary settings__description'>
						{translations.gambles.enableGambleConditions.description}
					</p>
				</div>
				<p className='h6 mb-2'>{translations.linkOpenType.title}</p>
				<Form.Group className='mb-3' controlId='linkOpenType'>
					<div>
						<Form.Check
							inline
							className='checkbox-black'
							type='radio'
							label={translations.linkOpenType.newWindow}
							id='linkOpenType2'
							value={LinkOpenType.NEW_WINDOW}
							required
							onChange={onChangeOpenTypeHandler}
							checked={settings.gambleOpenType === LinkOpenType.NEW_WINDOW}
						/>
					</div>
					<div>
						<Form.Check
							inline
							className='checkbox-black'
							type='radio'
							label={translations.linkOpenType.currentWindow}
							id='linkOpenType1'
							value={LinkOpenType.CURRENT_WINDOW}
							required
							onChange={onChangeOpenTypeHandler}
							checked={settings.gambleOpenType === LinkOpenType.CURRENT_WINDOW}
						/>
					</div>

					<div>
						<Form.Check
							inline
							className='checkbox-black'
							type='radio'
							label={translations.linkOpenType.postMessage}
							id='linkOpenType3'
							value={LinkOpenType.POST_MESSAGE}
							required
							onChange={onChangeOpenTypeHandler}
							checked={settings.gambleOpenType === LinkOpenType.POST_MESSAGE}
						/>
					</div>
				</Form.Group>
			</div>
			<div className='block w-50 mb-3'>
				<p className='text-secondary'>{translations.gambles.gambleIntervalMS.description}</p>
				<Form noValidate validated={validatedInterval} onSubmit={handleSubmitInterval}>
					<FloatingLabel
						controlId='gambleIntervalMS'
						label={translations.gambles.gambleIntervalMS.title}
						className='w-100 text-secondary mb-3'>
						<Form.Control
							name='gambleIntervalMS'
							placeholder={translations.gambles.gambleIntervalMS.title}
							value={mySettings.gambleIntervalMS}
							onChange={onChangeIntervalHandler}
							onBlur={onBlurHandler}
							required
						/>
					</FloatingLabel>
					<Button type='submit' variant='dark'>
						{translations.btns.save}
					</Button>
				</Form>
			</div>
			<div className='block w-50 mb-3'>
				<Form noValidate validated={validatedUrl} onSubmit={handleSubmitUrl}>
					<FloatingLabel
						controlId='eventGambleUrl'
						label='URL'
						className='w-100 text-secondary mb-3'>
						<Form.Control
							name='eventGambleUrl'
							placeholder='Url'
							value={mySettings.gambleUrl}
							onChange={onChangeUrlHandler}
							required
							type='url'
						/>
					</FloatingLabel>
					<FloatingLabel
						controlId='eventGambleUrl'
						label='Bearer'
						className='w-100 text-secondary mb-3'>
						<Form.Control
							name='eventGambleBearer'
							placeholder='Bearer'
							value={mySettings.gambleBearer}
							onChange={onChangeBearerHandler}
						/>
					</FloatingLabel>
					<Button type='submit' variant='dark'>
						{translations.btns.save}
					</Button>
				</Form>
			</div>
		</Container>
	);
};

export default observer(Gamble);
